import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { sentInvitation } from "../../../api/users.ts";
import LoadingSpinner from "../../../components/loading-spinner";
import { useMyInfo } from "../../../hooks/user.hooks.ts";
import { getCurrentHostname } from "../../../utils";
import {
  checkEmailVerification,
  checkPhoneVerification,
} from "../../../utils/email-verification";
import CustomSharingInput from "./custom-sharing-input";

const SharingButtonContainer = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState("Copy link");
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeInput = (value) => {
    setInputValue(value);
  };
  const hostName = getCurrentHostname();
  const userInfo = useMyInfo();
  const {
    data: { referralCode = "", referralUrl = "" },
  } = userInfo;

  const sentReferralInvitation = useMutation(
    (params) => sentInvitation(params),
    {
      onSuccess: () => {
        enqueueSnackbar("Your invitation has been sent!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.data?.message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      },
      retry: 1,
    }
  );

  const copyToClipboard = () => {
    setCopySuccess(true);
  };

  const handleSendEmail = () => {
    if (checkEmailVerification(inputValue)) {
      setInputError("Please provide a valid email address");
    } else {
      sentReferralInvitation.mutate({ email: inputValue });
      setInputError("");
    }
  };
  const handleSendSMS = () => {
    if (selectedButton === "Send SMS") {
      if (checkPhoneVerification(inputValue)) {
        setInputError("Please provide a valid phone number");
      } else {
        sentReferralInvitation.mutate({ phoneNumber: inputValue });
        setInputError("");
      }
    }
  };

  const handleSelectedButton = (value) => {
    setInputError("");
    setSelectedButton(value);
  };

  useEffect(() => {
    setCopySuccess(false);
  }, [selectedButton]);
  const handleClickdButton = () => {
    switch (selectedButton) {
      case "Send Email":
        return handleSendEmail();
      case "Send SMS":
        return handleSendSMS();
      default:
        return copyToClipboard();
    }
  };
  useEffect(() => {
    if (copySuccess) {
      enqueueSnackbar("Referral URL has been copied!", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setCopySuccess(false);
    }
  }, [copySuccess, enqueueSnackbar]);
  useLayoutEffect(() => {
    switch (selectedButton) {
      case "Copy link":
        setInputValue(
          `https://${hostName}/referral/${referralUrl}/${referralCode}`
        );
        break;
      case "Send SMS":
        setInputValue("+61");
        break;

      default:
        setInputValue("");
        break;
    }
  }, [hostName, referralCode, referralUrl, selectedButton]);
  const inputPlaceholder = useMemo(() => {
    switch (selectedButton) {
      case "Send Email":
        return "Type email address in and click send";
      case "Send SMS":
        return "Type in mobile number and click send";
      default:
        return "";
    }
  }, [selectedButton]);
  const buttonText = useMemo(() => {
    switch (selectedButton) {
      case "Send Email":
        return "Send email";
      case "Send SMS":
        return "Send SMS";
      default:
        return "Copy link";
    }
  }, [selectedButton]);
  return (
    <>
      <Grid container className="sharing-referral-button-container">
        {["Copy link", "Send Email", "Send SMS"].map((item) => {
          return (
            <Grid item key={item}>
              <button
                type="button"
                tabIndex="0"
                onClick={() => {
                  handleSelectedButton(item);
                }}
                className={classNames("call-to-action-button", {
                  selected: selectedButton === item,
                })}
              >
                {item}
              </button>
            </Grid>
          );
        })}
      </Grid>
      <CustomSharingInput
        inputRef={textAreaRef}
        isDisabled={selectedButton === "Copy link"}
        isAPILoading={sentReferralInvitation.isLoading}
        isActivated
        placeholder={inputPlaceholder}
        handleChange={(e) => {
          handleChangeInput(e.target.value);
        }}
        handleBlur={() => {}}
        fieldName="text"
        value={inputValue}
        error={inputError}
        touched
        handleClickButton={handleClickdButton}
        buttonText={
          sentReferralInvitation.isLoading ? <LoadingSpinner /> : buttonText
        }
      />
    </>
  );
};

SharingButtonContainer.propTypes = {};

export default SharingButtonContainer;
